import React from "react";
import Home from "./pages/home/Home";
import TopBar from "./topbar/TopBar";


function App() {
  return (
    <>
    <TopBar />
    <Home />
    </>
  );
}

export default App;
