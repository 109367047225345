import React from "react";
import "./sidebar.css";

export default function Sidebar() {
    return (
        <div className="sidebar">
            <div className="sidebaritem">
                <span className="sidebarTitle">ABOUT ME</span>
                <img
                    className="me-image"
                    src={require("./../about.jpeg")}
                    alt="Nanda" />
                <p>
                    This is my first blog website,
                    Please help me get a good traffic.
                    Need to find some sponsors for advertisments.
                </p>
                <div className="sidebarItem">
                    <span className="sidebarTitle">CATEGORIES</span>
                    <ul>
                        <li className="sidebarListItem">Life</li>
                        <li className="sidebarListItem">Music</li>
                        <li className="sidebarListItem">Style</li>
                        <li className="sidebarListItem">Sports</li>
                        <li className="sidebarListItem">Tech</li>
                        <li className="sidebarListItem">Cinema</li>
                    </ul>
                </div>
                <div className="sidebarItem">
                    <span className="sidebarTitle">FOLLOW US</span>
                    <div className="sidebarSocial">
                        <i className="sidebarIcon fa-brands fa-facebook"></i>
                        <i className="sidebarIcon fa-brands fa-twitter"></i>
                        <i className="sidebarIcon fa-brands fa-square-instagram"></i>
                        <i className="sidebarIcon fa-brands fa-linkedin"></i>
                    </div>
                </div>
            </div>
        </div>
    )
}
