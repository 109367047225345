import React from "react";
import "./header.css";

export default function Header() {
    return (
        <div className="header">
            <div className="headerTitles">
                <span className="headerTitlesAb">........................**........................</span>
                <span className="headerTitlesLg">My First Blog</span>
                <span className="headerTitlesBe">........................**........................</span>
            </div>
            <img
                className="headerImg"
                src="https://images.pexels.com/photos/691668/pexels-photo-691668.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                alt="Header" />
        </div>
    )
}


