import React from "react";
import './posts.css';

export default function Posts() {
    return (
        <div className="posts">
            Posts
        </div>
    )
}
